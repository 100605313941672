export default {
    toolNum: 0,
    toolList: [{
        id: 1,
        bg: require('../image/toolimg1.png'),
        bg2: require('../image/toolimg1-1.png')
    }, {
        id: 2,
        bg: require('../image/toolimg2.png'),
        bg2: require('../image/toolimg2-1.png')
    }, {
        id: 3,
        bg: require('../image/toolimg3.png'),
        bg2: require('../image/toolimg3-1.png')
    }, {
        id: 4,
        bg: require('../image/toolimg4.png'),
        bg2: require('../image/toolimg4-1.png')
    }],
    phoneList: [{
        id: 1,
        bg: require('../image/marketing/swiper.png')
    }, {
        id: 2,
        bg: require('../image/marketing/swiper2.png')
    }, {
        id: 3,
        bg: require('../image/marketing/swiper3.png')
    }, {
        id: 4,
        bg: require('../image/marketing/swiper4.png')
    }, {
        id: 5,
        bg: require('../image/marketing/swiper5.png')
    }],
    DList: [{
        id: 1,
        logo: require('../image/marketing/logo-1.png'),
        title: '品牌营销',
        p1: '品牌形象设计',
        p2: '品牌推广',
        p3: 'SEO优化',
        p4: '网站推广',
    }, {
        id: 2,
        logo: require('../image/marketing/logo-2.png'),
        title: '数字营销',
        p1: '大转盘H5游戏',
        p2: '码券兑换活动',
        p3: '微信红包互动',
        p4: '微信红包互动',
    }, {
        id: 3,
        logo: require('../image/marketing/logo-3.png'),
        title: '全媒体营销',
        p1: '微信运营',
        p2: '自媒体矩阵',
        p3: '短视频',
        p4: '小程序开发',
    }],
}